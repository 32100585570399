import React, { useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';

const NotFoundPage = (props) => {
	const alertSentry = () => {
		const { pathname, search } = window.location;
		const { referrer } = document;
		let message = `:alert: 404 detected at: ${pathname}`;
		if (search?.length) {
			message += `\n Query: ${search}`;
		}
		if (referrer?.length) {
			message += `\n Referrer: ${referrer}`;
		}
		try {
			window.Sentry.captureMessage(message);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('alert sentry failed with error:', error);
		}
	};

	// we can't alert sentry until it loads, so we're listening here for it, then firing our alert function.
	useEffect(() => {
		const sentry = document.getElementById('sentry-async-script');
		sentry.addEventListener('load', alertSentry);
		return () => {
			sentry?.removeEventListener('load', alertSentry);
		};
	}, []);

	return (
		<Layout>
			<div className="container-fluid pt-5" style={{ minHeight: '60vh' }}>
				<div className="w-75 w-md-50 w-xl-25 mx-auto mt-5">
					<GatsbyImage
						alt="sign-post"
						className="img-fluid mx-auto"
						image={props.data.signPost.childImageSharp.gatsbyImageData}
					/>
				</div>
				<h3 className="my-5 text-center w-100">
					It's true not all who wander are lost, but you're definitely in the
					wrong place.
					{' '}
					<Link to="/" className="inline-link">
						This will take you home.
					</Link>
				</h3>
			</div>
		</Layout>
	);
};

export default NotFoundPage;

export const query = graphql`
	query {
		signPost: file(relativePath: { regex: "/sign_full/" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
